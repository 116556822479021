import axios from 'axios';
import MaterialTable from "material-table";
import React , { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from '@material-ui/core/InputLabel';
import SearchIcon from "@material-ui/icons/Search";
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { withStyles } from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";

import './Coverlookup.scss';
import Constants from '../../constants';
import { useUIContext } from '../../ContextLib/contextHooks';
import { Severity } from '../../ContextLib/CoreConsumer/Components/SnackbarMessage';
import Storage from '../../storage';
import MenuItem from '@material-ui/core/MenuItem';

export default function SyncCRMCatalog() {
    let componentId = "SyncCRMCatalog";
    const ui = useUIContext(componentId);
    const [searchTypeID, setSearchTypeID] = useState("coverkey");
    const [searchInput, setSearchInput] = useState("");
    const [CoverKey, setCoverKey] = useState(0);

    const [coverData, setCoverData] = useState([]);
    const [locationData, setLocationData] = useState([]);
    const [accountData, setAccountData] = useState([]);

    const [programAssociationOptions, setProgramAssociationOptions] = useState([]);
    const [selectedprogramAssociationOption, setSelectedProgramAssociationOption] = useState(0);
    const [programName, setProgramName] = useState('No Program Association Mapped');

    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
          backgroundColor: '#f5f5f9',
          color: 'rgba(0, 0, 0, 0.87)',
          maxWidth: 250,
          fontSize: theme.typography.pxToRem(16),
          border: '1px solid #dadde9',
          fontFamily:'Noto Sans, sans-serif',
        },
      }))(Tooltip)

    const locationColumns = [
        // { field: 'coverKey', title: 'CoverKey', width: 100, editable: 'never', emptyValue: '-'},
        { field: 'locKey', title: 'LocKey', width: 100, editable: 'never', emptyValue: '-'},
        { field: 'locationName', title: 'Location Name', width: 150, editable: 'never', emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.locationName} placement="top-start"><span>{dataRow.locationName}</span></HtmlTooltip>}},
        { field: 'locationAddress1', title: 'Address 1', width: 150, editable: 'never', emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.locationAddress1} placement="top-start"><span>{dataRow.locationAddress1}</span></HtmlTooltip>}},
        { field: 'locationAddress2', title: 'Address 2', width: 150, editable: 'never', emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.locationAddress2} placement="top-start"><span>{dataRow.locationAddress2}</span></HtmlTooltip>}},
        { field: 'locationCity', title: 'City', width: 125, editable: 'never', emptyValue: '-', cellStyle: {wordWrap: 'break-word'}},
        { field: 'locationState', title: 'State', width: 100, editable: 'never', emptyValue: '-'},
        { field: 'locationZip', title: 'ZIP Code', width: 130, editable: 'never', emptyValue: '-', cellStyle: {wordWrap: 'break-word'}},
        { field: 'phoneNumber', title: 'Phone', width: 125, editable: 'never', emptyValue: '-', cellStyle: {wordWrap: 'break-word'}},
        { field: 'timeZone', title: 'Time Zone', width: 100, editable: 'never', emptyValue: '-'},
        { field: 'logo', title: 'Logo', width: 150, editable: 'never', emptyValue: '-', cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}, render: dataRow => {return <HtmlTooltip title={dataRow.logo} placement="top-start"><span>{dataRow.logo}</span></HtmlTooltip>}},
    ];

    const accountColumns = [
        { field: 'coverKey', title: 'CoverKey', width: 100, editable: 'never', emptyValue: '-'},
        // { field: 'locKey', title: 'LocKey', width: 100, editable: 'never', emptyValue: '-'},
        { field: 'acctKey', title: 'AcctKey', width: 100, editable: 'never', emptyValue: '-'},
        { field: 'pmsProviderID', title: 'PMS ProviderID', width: 100, editable: 'never', emptyValue: '-'},
        { field: 'providerName', title: 'Provider Name', width: 150 , emptyValue: '-', cellStyle: {wordWrap: 'break-word'}},
    ];

    // const stateOptions = [
    //     {key: "AL", value: "AL"}, {key: "AK", value: "AK"}, {key: "AZ", value: "AZ"}, {key: "AR", value: "AR"}, {key: "CA", value: "CA"}, {key: "CO", value: "CO"}, {key: "CT", value: "CT"}, {key: "DE", value: "DE"},
    //     {key: "FL", value: "FL"}, {key: "GA", value: "GA"}, {key: "HI", value: "HI"}, {key: "ID", value: "ID"}, {key: "IL", value: "IL"}, {key: "IN", value: "IN"}, {key: "IA", value: "IA"}, {key: "KS", value: "KS"}, 
    //     {key: "KY", value: "KY"}, {key: "LA", value: "LA"}, {key: "ME", value: "ME"}, {key: "MD", value: "MD"}, {key: "MA", value: "MA"}, {key: "MI", value: "MI"}, {key: "MN", value: "MN"}, {key: "MS", value: "MS"}, 
    //     {key: "MO", value: "MO"}, {key: "MT", value: "MT"}, {key: "NE", value: "NE"}, {key: "NV", value: "NV"}, {key: "NH", value: "NH"}, {key: "NJ", value: "NJ"}, {key: "NM", value: "NM"}, {key: "NY", value: "NY"}, 
    //     {key: "NC", value: "NC"}, {key: "ND", value: "ND"}, {key: "OH", value: "OH"}, {key: "OK", value: "OK"}, {key: "OR", value: "OR"}, {key: "PA", value: "PA"}, {key: "RI", value: "RI"}, {key: "SC", value: "SC"}, 
    //     {key: "SD", value: "SD"}, {key: "TN", value: "TN"}, {key: "TX", value: "TX"}, {key: "UT", value: "UT"}, {key: "VT", value: "VT"}, {key: "VA", value: "VA"}, {key: "WA", value: "WA"}, {key: "WV", value: "WV"}, 
    //     {key: "WI", value: "WI"}, {key: "WY", value: "WY"}   
    // ];

    const searchOptions = [
        // {key : "covername" , value : "Cover Name"},
        {key : "coverkey" , value : "Cover Key"}
    ];

   
    const componentDidMount = async () => {
        const user = Storage.getItem(Constants.currUserKey);
        let redirectToLogin = false;
        if (user && user.jwt) {
          try {
            ui.ShowOverlay();
            const jwtCheckUrl = `${process.env.REACT_APP_ADMIN_API}/jwtCheck`;
            await axios.get(jwtCheckUrl);
            ui.HideOverlay();
          }
          catch (error) {
            ui.ShowSnackbar(error, Severity.error);
            redirectToLogin = true;
          }
        } 
        else {
            redirectToLogin = true;
        }

        if (redirectToLogin) return <Redirect to={{ pathname: '/' }} />
    }   
    

    useEffect(() => {        
        componentDidMount();
    }, []);

    const getLocationsAndAccounts = () => {
        getCatalogData();
        getProviders();
        getProgramAssociationByCover();
    }

    useEffect(() => {        
        getProgramAssociationData();
    }, []);
 
    const getProgramAssociationByCover = async () => {
        try {
            ui.ShowOverlay();
            var request = {
                params: {
                  secretCode: `${process.env.REACT_APP_API_KEY}`,
                  coverKey:searchInput
                }
              };
              let url = `${process.env.REACT_APP_JOBSCHEDULER_API}/Job/getProgramAssociationByCover`;
              const res = await axios.get(url, request);
              let d = res;

               const name = res.data.programName === undefined ? 'No Program Association Mapped': res.data.programName;
               setProgramName(name);
               
        } catch (error) {
            ui.ShowSnackbar("Unxexpected Error retrieving Program Association.", Severity.error);
        }
        ui.HideOverlay();
    }
    const getProgramAssociationData = async () => {
        try {
            ui.ShowOverlay();
            var request = {
                params: {
                  secretCode: `${process.env.REACT_APP_API_KEY}`
                }
              };
              let url = `${process.env.REACT_APP_JOBSCHEDULER_API}/Job/getProgramAssociation`;
              const res = await axios.get(url, request);
              let d = res.data;
              if (res?.data.length > 0) { 
                const formattedOptions = res.data.map(item => ({
                    value: item.programAssociationID,
                    label: item.programName
                  }));
                setProgramAssociationOptions(formattedOptions);
              } else {
                setProgramAssociationOptions([]); 
                setSelectedProgramAssociationOption(0);
              }

        } catch (error) {
            ui.ShowSnackbar("Unxexpected Error retrieving Program Association.", Severity.error);
            setProgramAssociationOptions([]);
        }
        ui.HideOverlay();
    }

    const handleSelectChangeProgramAssociation = event => {
        setSelectedProgramAssociationOption(event.target.value);
      
      };

    const getCatalogData = async () => {
        try {
            ui.ShowOverlay();
            var request = {
                params: {
                  secretCode: `${process.env.REACT_APP_API_KEY}`,
                  queryType: searchTypeID,
                  queryString: searchInput
                }
              };
              const url = `${process.env.REACT_APP_COVERLOOKUP_API}/getCatalogDataByCover`;
              const res = await axios.get(url, request);
              let d = res.data;
              if (res?.data.length > 0) { 
                  setCoverKey(d[0].coverKey);
                  setLocationData(d); 
              } else {
                ui.ShowSnackbar("No locations for this cover.", Severity.warning); 
                setLocationData([]); 
                setCoverKey(0);
              }

        } catch (error) {
            ui.ShowSnackbar("Invalid search parameter! Please update search value and try again.", Severity.error);
            setLocationData([]);
        }
        ui.HideOverlay();
    }

    const getProviders = async () => {
        try {
            ui.ShowOverlay();
            var request = {
                params: {
                  secretCode: `${process.env.REACT_APP_API_KEY}`,
                  coverKey: searchInput
                }
              };
              const url = `${process.env.REACT_APP_COVERLOOKUP_API}/getProviderByCover`;
              const res = await axios.get(url, request);
              let d = res.data;
              if (res?.data.length > 0) { 
                setAccountData(d); 
              } else {
                ui.ShowSnackbar("No providers for this cover.", Severity.warning); 
                setAccountData([]); 
              }

        } catch (error) {
            ui.ShowSnackbar("Invalid search parameter! Please update search value and try again.", Severity.error);
            setAccountData([]); 
        }
        ui.HideOverlay();
    }

    const handleSyncCatalog = async () => {
        try {
            ui.ShowOverlay();
              let sProgAssociationID=selectedprogramAssociationOption ===0 || selectedprogramAssociationOption ==="0"  ? 1: selectedprogramAssociationOption;

              var coverKey = parseInt(searchInput);
              let url = `${process.env.REACT_APP_JOBSCHEDULER_API}/AdhocHangfire/updateCatalog?coverKey=${coverKey}&secretCode=${process.env.REACT_APP_API_KEY}&programAssociationID=${sProgAssociationID}`;
           

              let res = await axios.post(url);
              if (res.status === 200) { 
                ui.ShowSnackbar("CRM Catalog/Provider were synced successfully.", Severity.success);
              } else {
                ui.ShowSnackbar("Unexpected error in catalog Synced. Please try again later.", Severity.warning); 
              }
        } catch (error) {

            ui.ShowSnackbar("Invalid search parameter! Please update search value and try again.", Severity.error);
        }
        ui.HideOverlay();
    }



    const handelsetSearchTypeID=(e) => {  
        setSearchTypeID('coverkey');
        setSearchInput('');
    }

    

    return(
        <div className="location-provider">
            <Box p={2} bgcolor="background.paper">
                <Grid container justify="flex-start" alignItems="center" spacing={2}>
                    <Grid item>
                        <Typography variant="h5">Cover Data</Typography>
                    </Grid>

                    <Divider orientation="vertical" variant="middle" flexItem/>

                    <Grid item>
                        <Typography variant="h6">  
                            Sync CRM Catalog
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            <Divider />

            <Box p={4} bgcolor="background.paper" >
                <div className="location-provider-data">
                    <ValidatorForm onSubmit={getLocationsAndAccounts} onError={errors => console.log(errors)} > 
                    <Grid container justify="flex-start" alignItems="center" spacing={2} className="search-option">
    <Grid item>
        <Typography variant="h6">Enter CoverKey: </Typography>
    </Grid>

    <Grid item>
        <TextValidator
            style={{ width: "170px" }}
            id="searchInput"
            autoComplete="off"
            placeholder="Search Value"
            value={searchInput}
            onInput={e => setSearchInput(e.target.value)}
            validators={['required']}
            errorMessages={['Search Value is required']}
            InputProps={{
                disableUnderline: true,
                startAdornment: (
                    <InputAdornment position="start">
                        <IconButton >
                            <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    </Grid>

    <Grid item>
        <Button style={{ maxWidth: '100px', minWidth: '100px' }} variant="contained" color="primary" type="submit">
            SEARCH
        </Button>
    </Grid>
</Grid>

<Grid container justify="flex-start" alignItems="center" spacing={2} className="search-option">
    {CoverKey !== 0 ?
        <Grid item>
            <Typography variant="h6">Program Association: </Typography>
        </Grid>
        :
        <></>
    }
    {CoverKey !== 0 ?
    <Grid item>
        <FormControl>
            <Select
                style={{ width: "170px" }}
                disableUnderline
                native
                value={selectedprogramAssociationOption}
                onChange={handleSelectChangeProgramAssociation}
            >
                <option value="0">Select Program...</option>
                {programAssociationOptions.map(option => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </Select>
        </FormControl>
    </Grid>
        :
        <></>
    }
    {CoverKey !== 0 ?
        <Grid item>
            <Button onClick={() => handleSyncCatalog()} style={{ maxWidth: '200px', minWidth: '200px' }} variant="contained" color="primary">
                Sync to Catalog
            </Button>
        </Grid>
        :
        <></>
    }
</Grid>
                </ValidatorForm>
                
                <Divider />
                { CoverKey === 0 ? 
                    <Box p={4}>
                        <Typography align="center" variant="h6">Please search for a cover</Typography>
                    </Box>
                    :
                    <>
                      {/* <div style={{ height: 'auto', overflow: 'auto', width: '900px' }}> */}
                            <MaterialTable
                                title={programName}
                                height="150"
                                data={locationData}
                                columns={locationColumns}                               
                                options={{
                                    pageSize: 5,
                                    pageSizeOptions: [10, 15, 20],
                                    paging: true,
                                    draggable: false,
                                    sorting: false,
                                    tableLayout: 'fixed',
                                }}
                                /> 

                      {/* </div> */}
                   

                    <Divider className="divider-thick"/>
      
                    <MaterialTable
                        height="200"
                        data={accountData}
                        columns={accountColumns}
                        title="Providers"
                        options={{
                            pageSize: 5,
                            pageSizeOptions: [10, 15, 20],
                            paging: true,
                            draggable: false,
                            sorting: false,
                            tableLayout: 'fixed',
                        }}  
                    /> 
                    </>
                }
                </div>
            </Box>
        </div>
    )
}