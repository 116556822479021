import React, { useState, useMemo, useEffect } from 'react'
import InputAdornment from "@material-ui/core/InputAdornment";
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ValidatedTimePicker from '../../Components/TimePicker/ValidatedTimePicker';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DateFnsUtils from '@date-io/date-fns';
import MaterialCheckBox from '../PmsConfig/Components/Common/MaterialCheckBox';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import countryList from "react-select-country-list";
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Overlay from '../../Components/Overlay/Overlay';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Storage from '../../storage';
import { Autocomplete } from '@material-ui/lab';
import FormControl from '@material-ui/core/FormControl';
import Enums from '../../enums';


export default function HostedEcpDetails() {
  const [coverKey, setCoverKey] = useState(null);
  const [coverKeyLabel, setCoverKeyLabel] = useState('');
  const [locKey, setLocKey] = useState(null);
  const [locKeyLabel, setLocKeyLabel] = useState('');
  const [acctKey, setAcctKey] = useState(null);
  const [ecpDescription, setDescription] = useState('');
  const [ecpProviderConfigId, setProviderConfigId] = useState(null);
  const [ecpProviderTypeId, setProviderTypeId] = useState(1);
  const [ecpMessageTypeId, setPMessageTypeId] = useState(1);
  const [ecpTwillioSid, setTwillioSid] = useState('');
  const [ecpMessageSid, setMessageSid] = useState('');
  const [ecpAuthCode, setAuthCode] = useState('');
  const [ecpCountryCode, setCountryCode] = useState('');
  const [ecpCommStartTime, setCommStartTime] = useState(null);
  const [ecpCommEndTime, setCommEndTime] = useState(null);
  const [ecpUtcOffsetHour, setUtcOffsetHour] = useState(null);
  const [ecpUtcOffsetMinute, setUtcOffsetMinute] = useState(null);
  const [ecpCheckforUnsol, setCheckforUnsol] = useState(false);
  const [ecpIsDefault, setIsDefault] = useState(false);
  const [ecpEnableCoverKey, setEnableCoverKey] = useState(false);
  const [lockeyList, setLocKeyList] = useState([{}]);
  const [acctKeyList, setAcctKeyList] = useState([{}]);
  const [validationErrorMsgs, setValidationErrorMsgs] = useState([{}]);
  const [isRecordExists, setIsRecordExists] = useState(false);
  const [isCoverKeyExists, setIsCoverKeyExists] = useState(false);
  const [isDuplicateMessageGroupId, setIsDuplicateMessageGroupId] = useState(false);
  const [isOverrideMessageGroupId, setIsOverrideMessageGroupId] = useState(false);
  const [isFilterLocKeyDisabled, setIsFilterLocKeyDisabled] = useState(true);
  const [isFilterAcctKeyDisabled, setIsFilteAcctKeyDisabled] = useState(true);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);
  const [submitButtonLabel, setSubmitButtonLabel] = useState('Add');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const countryCodes = useMemo(() => countryList().getData(), []);
  const [coverKeyList, setCoverKeyList] = useState([{}]);

  const [showLoadingOverlay, setshowLoadingOverlay] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState('');
  const [errorSeverity, setErrorSeverity] = useState('success');

  const loadCoverKeyData = async () => {
    setshowLoadingOverlay(true);
    try {
      const apiKey = `${process.env.REACT_APP_API_KEY}`;
      const url = `${process.env.REACT_APP_HOSTEDECP_API}/CoverKeyList/?secretCode=${apiKey}`;
      const response = await axios.get(url);
      let d = response.data
      if (response?.data.length > 0) {
        setCoverKeyList(d)
      }
      else {
        setLocKeyList([]);
        let message = 'Cover does not exist in the database.';
        setErrorSeverity("warning");
        setOpenSnackBar(true);
        setSnackbarMessage(message);
      }
    } catch (error) {
      console.error("Unexpected error in getting the coverKey list:", error);
    }
    setshowLoadingOverlay(false);
  };

  useEffect(() => {
    loadCoverKeyData();
    setCoverKeyLabel(coverKey === null ? "" : "Select Cover");
  }, []);

  const CoverKeyChange = async (coverKey) => {
    _refreshForm();
    setLocKeyLabel('');
    setLocKey('');
    setLocKeyList([]);
    setSubmitButtonLabel('Add');
    setIsRecordExists(false);

    if (coverKey === '' || coverKey === null) {
      setLocKeyList([]);
      setIsFilterLocKeyDisabled(true);
      setCoverKey('');
      setCoverKeyLabel('Select Cover');
      setLocKey('');
      setLocKeyLabel('');
      _refreshForm();
    }
    else {
      setCoverKeyLabel(coverKey);
      let _coverKey = coverKey === '' ? coverKey : coverKey.split('-')[0];
      setCoverKey(_coverKey);
      await getLocKeyList(_coverKey);
      coverKey === '' ? setIsFilterLocKeyDisabled(true) : setIsFilterLocKeyDisabled(false)
    }
  }

  const getLocKeyList = async (coverKey) => {
    setshowLoadingOverlay(true);
    try {
      const apiKey = `${process.env.REACT_APP_API_KEY}`;
      const url = `${process.env.REACT_APP_HOSTEDECP_API}/SearchLocKeyByCoverKey/${Number(coverKey)}?secretCode=${apiKey}`;
      const response = await axios.get(url);
      let d = response.data
      if (response?.data.length > 0) {
        setLocKeyList(d)
        setIsFilterLocKeyDisabled(false);
        setIsAddButtonDisabled(false);
      }
      else {
        setLocKeyList([]);
        setIsFilterLocKeyDisabled(true);
        setAcctKeyList([]);
        setIsFilteAcctKeyDisabled(true);
        setIsAddButtonDisabled(true);

        let message = 'No available LocKeys';
        setErrorSeverity("warning");
        setOpenSnackBar(true);
        setSnackbarMessage(message);

      }
    } catch (error) {
      console.error("Unexpected error in getting the locKey list:", error);
    }
    setshowLoadingOverlay(false);
  };

  const LocKeyChange = async (locKey) => {
    if (locKey === '' || locKey === null) {
      setLocKey('');
      setLocKeyLabel('');
      _refreshForm();
    }
    else {
      setLocKeyLabel(locKey);
      let _locKey = locKey === '' ? locKey : locKey.split('-')[0];
      setLocKey(_locKey);
      await filterByLocKeyLevel(_locKey)
      setIsFilterLocKeyDisabled(false);
    }
  }

  const filterByLocKeyLevel = async (locKey) => {
    setshowLoadingOverlay(true);
    try {
      const apiKey = `${process.env.REACT_APP_API_KEY}`;
      const url = `${process.env.REACT_APP_HOSTEDECP_API}/FilterByLocKeyLevel/${Number(coverKey)}/${Number(locKey)}?secretCode=${apiKey}`;
      const response = await axios.get(url);
      let d = response.data
      if (response?.data !== null && response?.data !== '') {
        setCoverKey(d.coverKey);
        setLocKey(locKey);
        setAcctKey(null);
        setDescription(d.description);
        setProviderConfigId(d.providerConfigId);
        setTwillioSid(d.twillioSid);
        setAuthCode(d.authCode);
        setMessageSid(d.messageSid);
        getCountryCodeLabel(d.countryCode);
        setCommStartTime(d.commStartTime);
        setCommEndTime(d.commEndTime);
        setUtcOffsetHour(d.utcOffsetHour);
        setUtcOffsetMinute(d.utcOffsetMinute);
        setCheckforUnsol(d.checkForUnsol);
        setIsDefault(d.isDefault);
      }
      else {
        _refreshForm();
        setLocKey(locKey)
        setAcctKey('')
        setAcctKeyList([]);
      }
      await checkIfRecordExists(locKey);
    } catch (error) {
      console.error("Unexpected error in filtering the records by locKey level:", error);
    }
    setshowLoadingOverlay(false);
  };

  const checkIfCoverKeyExists = async () => {
    try {
      const apiKey = `${process.env.REACT_APP_API_KEY}`;
      var url = `${process.env.REACT_APP_HOSTEDECP_API}/Validate/IsCoverKeyExists/${Number(coverKey)}?secretCode=${apiKey}`;
      const response = await axios.get(url);
      setCoverKey(coverKey);

      if (response.data <= 0) {
        setIsCoverKeyExists(false);
        return false;
      }
      else {
        setIsCoverKeyExists(true);
        return true;
      }
    } catch (error) {
      console.error("Unexpected error in checking if the coverKey exists:", error);
    }
  };

  const checkIfRecordExists = async (locKey) => {
    try {
      const apiKey = `${process.env.REACT_APP_API_KEY}`;
      var url = `${process.env.REACT_APP_HOSTEDECP_API}/Validate/IsRecordExists/${Number(coverKey)}/${Number(locKey)}?secretCode=${apiKey}`;
      const response = await axios.get(url);
      setCoverKey(coverKey);

      if (response.data <= 0) {
        setIsRecordExists(false);
        setSubmitButtonLabel('Add');
      }
      else {
        setIsRecordExists(true);
        setSubmitButtonLabel('Save');
      }
    } catch (error) {
      console.error("Unexpected error in checking if the record is already existing:", error);
    }
  };

  const checkIfDuplicateMessageGroupId = async (messageSid) => {
    setshowLoadingOverlay(true);
    try {
      const apiKey = `${process.env.REACT_APP_API_KEY}`;
      var url = `${process.env.REACT_APP_HOSTEDECP_API}/Validate/IsDuplicateMessageGroupId/${Number(coverKey)}/${Number(locKey)}/${messageSid}?secretCode=${apiKey}`;
      const response = await axios.get(url);

      if (response.data >= 1) {
        setIsDuplicateMessageGroupId(true);
        let message = 'Message Group Id should be unique';
        setErrorSeverity("warning");
        setOpenSnackBar(true);
        setSnackbarMessage(message);
        setMessageSid('');
        setshowLoadingOverlay(false);
        return true;
      }
      else {
        setIsDuplicateMessageGroupId(false);
        setshowLoadingOverlay(false);
        return false;
      }

    } catch (error) {
      console.error("Unexpected error in checking if the message group id is duplicated:", error);
    }
    setshowLoadingOverlay(false);
  };

  const _handleOverrideMessageGroupId = async (isOverrideMessageGroupId) => {
    setIsOverrideMessageGroupId(isOverrideMessageGroupId)
    if (isDuplicateMessageGroupId === true && isOverrideMessageGroupId === false) {
      checkIfDuplicateMessageGroupId(ecpMessageSid);
    }
  };

  const _checkValidations = async () => {
    setshowLoadingOverlay(true);
    const isDuplicate = false;
    if (isDuplicateMessageGroupId === true && isOverrideMessageGroupId === false)  {
      isDuplicate = checkIfDuplicateMessageGroupId(ecpMessageSid);
    }

    if (!isDuplicate) {
      try {
        const apiKey = `${process.env.REACT_APP_API_KEY}`;
        const data = {
          'coverKey': Number(coverKey),
          'locKey': Number(locKey),
          'acctKey': Number(acctKey),
          'description': ecpDescription,
          'providerTypeId': ecpProviderTypeId,
          'messageTypeId': ecpMessageTypeId,
          'twillioSid': ecpTwillioSid,
          'messageSid': ecpMessageSid,
          'authCode': ecpAuthCode,
          'countryCode': getCountryCodeValue(ecpCountryCode),
          'commStartTime': convertTo24HourFormat(ecpCommStartTime),
          'commEndTime': convertTo24HourFormat(ecpCommEndTime),
          'utcOffsetHour': Number(ecpUtcOffsetHour),
          'utcOffsetMinute': Number(ecpUtcOffsetMinute),
          'checkForUnsol': ecpCheckforUnsol,
          'isDefault': ecpIsDefault,
        };
        const url = `${process.env.REACT_APP_HOSTEDECP_API}/CheckValidations?secretCode=${apiKey}`;
        const res = await axios.post(url, data);


        if (res?.data.length > 0) {
          setValidationErrorMsgs(res.data);
          return res.data;
        } else {
          return null;
        }
      } catch {
        let message = 'Unexpected error in checking the validations';
        setErrorSeverity("error");
        setOpenSnackBar(true);
        setSnackbarMessage(message);
      }
    }
    setshowLoadingOverlay(false);
  };

  const _handleSubmitButton = async () => {
    if (!isRecordExists) {
      await _handleAddHosted();
    }
    else {
      await _handleUpdateHosted();
    }
  };


  const _handleAddHosted = async () => {
    setshowLoadingOverlay(true);
    var validations = await _checkValidations();

    try {
      if (validations == null) {
        const apiKey = `${process.env.REACT_APP_API_KEY}`;
        const data = {
          'coverKey': Number(coverKey),
          'locKey': Number(locKey),
          'acctKey': Number(acctKey),
          'description': ecpDescription,
          'providerTypeId': ecpProviderTypeId,
          'messageTypeId': ecpMessageTypeId,
          'twillioSid': ecpTwillioSid,
          'messageSid': ecpMessageSid,
          'authCode': ecpAuthCode,
          'countryCode': getCountryCodeValue(ecpCountryCode),
          'commStartTime': convertTo24HourFormat(ecpCommStartTime),
          'commEndTime': convertTo24HourFormat(ecpCommEndTime),
          'utcOffsetHour': Number(ecpUtcOffsetHour),
          'utcOffsetMinute': Number(ecpUtcOffsetMinute),
          'checkForUnsol': ecpCheckforUnsol,
          'isDefault': ecpIsDefault,
        };
        const url = `${process.env.REACT_APP_HOSTEDECP_API}/Details?secretCode=${apiKey}`;
        const res = await axios.post(url, data);

        if (res?.data) {
          var resultInsert = await _handleInsertIntoJ45CoverXServiceType();
          let message = "";

          if (resultInsert === true) {
            message = `CoverKey: ${coverKey} LocKey: ${locKey} successfully hosted.`;
          }
          else {
            message = `Error Occurred. CoverKey: ${coverKey} LocKey: ${locKey} was partially hosted.`;
          }

          const auditData = {
            'key': Number(coverKey),
            'userId': Storage.getItem('userId'),
            'type': Enums.HostingAuditTrailTypeId.create,
            'description': `Hosting - LocKey: ${locKey}`,
          };

          await _handleInsertAuditTrail(auditData);

          setErrorSeverity("success");
          setSnackbarMessage(message);
          setOpenSnackBar(true);
          setCoverKey('');
          setCoverKeyLabel('');
          setLocKey('');
          setLocKeyLabel('');
          setLocKeyList([]);
          setAcctKey('');
          setAcctKeyList([]);
          setIsFilteAcctKeyDisabled(true);
          setIsFilterLocKeyDisabled(true);
          _refreshForm();
        }
        else {
          let message = 'Error in saving hosted Details.';
          setErrorSeverity("error");
          setOpenSnackBar(true);
          setSnackbarMessage(message);
        }
      }
      else {
        setErrorSeverity("error");
        setOpenSnackBar(true);
        setSnackbarMessage(validations.map(item => (
          <div key={item.field}>
            <p>{item.field}: {item.errorMessage}</p>
          </div>
        )));
      }
    }

    catch {
      let message = 'Unexpected error in saving hosted details.';
      setErrorSeverity("error");
      setOpenSnackBar(true);
      setSnackbarMessage(message);
    }
    setshowLoadingOverlay(false);
  };

  const _handleUpdateHosted = async () => {
    setshowLoadingOverlay(true);
    var validations = await _checkValidations();


    try {
      if (validations == null) {
        const apiKey = `${process.env.REACT_APP_API_KEY}`;
        const data = {
          'coverKey': Number(coverKey),
          'locKey': Number(locKey),
          'acctKey': Number(acctKey),
          'description': ecpDescription,
          'providerConfigId': ecpProviderConfigId,
          'providerTypeId': ecpProviderTypeId,
          'messageTypeId': ecpMessageTypeId,
          'twillioSid': ecpTwillioSid,
          'messageSid': ecpMessageSid,
          'authCode': ecpAuthCode,
          'countryCode': getCountryCodeValue(ecpCountryCode),
          'commStartTime': convertTo24HourFormat(ecpCommStartTime),
          'commEndTime': convertTo24HourFormat(ecpCommEndTime),
          'utcOffsetHour': Number(ecpUtcOffsetHour),
          'utcOffsetMinute': Number(ecpUtcOffsetMinute),
          'checkForUnsol': ecpCheckforUnsol,
          'isDefault': ecpIsDefault,
        };
        const url = `${process.env.REACT_APP_HOSTEDECP_API}/Details?secretCode=${apiKey}`;
        const res = await axios.put(url, data);

        if (res?.data) {
          let message = 'Hosted Details updated successfully.';
          setErrorSeverity("success");
          setSnackbarMessage(message);
          setOpenSnackBar(true);
          setCoverKey('');
          setCoverKeyLabel('');
          setLocKey('');
          setLocKeyLabel('');
          setLocKeyList([]);
          setAcctKey('')
          setAcctKeyList([]);
          setIsFilteAcctKeyDisabled(true);
          setIsFilterLocKeyDisabled(true);
          _refreshForm();

          const auditData = {
            'key': Number(coverKey),
            'userId': Storage.getItem('userId'),
            'type': Enums.HostingAuditTrailTypeId.update,
            'description': `Hosting - LocKey: ${locKey}`,
          };

          await _handleInsertAuditTrail(auditData);
        }
        else {
          let message = 'Error in updating hosted Details.';
          setErrorSeverity("error");
          setOpenSnackBar(true);
          setSnackbarMessage(message);
        }
      }
      else {
        setErrorSeverity("error");
        setOpenSnackBar(true);
        setSnackbarMessage(validations.map(item => (
          <div key={item.field}>
            <p>{item.field}: {item.errorMessage}</p>
          </div>
        )));
      }
    }
    catch {
      let message = 'Unexpected error in updating hosted details.';
      setErrorSeverity("error");
      setOpenSnackBar(true);
      setSnackbarMessage(message);
    }
    setshowLoadingOverlay(false);
  };

  const _handleRemoveHostedCheck = async (event) => {
    try { event.preventDefault(); } catch { }
    setOpenConfirmDialog(true);
  }

  const _handleRemoveHosted = async () => {
    setshowLoadingOverlay(true);
    try {
      const apiKey = `${process.env.REACT_APP_API_KEY}`;
      const url = `${process.env.REACT_APP_HOSTEDECP_API}/Details/${ecpProviderConfigId}?secretCode=${apiKey}`;
      const res = await axios.delete(url);

      if (res?.data) {
        setOpenConfirmDialog(false);
        let message = 'Hosted Details deleted successfully.';
        setErrorSeverity("success");
        setSnackbarMessage(message);
        setOpenSnackBar(true);
        setCoverKey('');
        setCoverKeyLabel('');
        setLocKey('');
        setLocKeyLabel('');
        setLocKeyList([]);
        setAcctKey('')
        setAcctKeyList([]);
        setIsFilteAcctKeyDisabled(true);
        setIsFilterLocKeyDisabled(true);
        _refreshForm();

        const auditData = {
          'key': Number(coverKey),
          'userId': Storage.getItem('userId'),
          'type': Enums.HostingAuditTrailTypeId.remove,
          'description': `Hosting - LocKey: ${locKey}`,
        };

        await _handleInsertAuditTrail(auditData);
      }
      else {
        let message = 'Error in deleting hosted details.';
        setErrorSeverity("error");
        setOpenSnackBar(true);
        setSnackbarMessage(message);
      }
    }
    catch {
      let message = 'Unexpected error in deleting hosted details.';
      setErrorSeverity("error");
      setOpenSnackBar(true);
      setSnackbarMessage(message);
    }
    setshowLoadingOverlay(false);
  };

  const _handleInsertIntoJ45CoverXServiceType = async () => {
    setshowLoadingOverlay(true);
    var isCoveKeyExists = false;
    var isRecordInserted = false;
    try {
      isCoveKeyExists = await checkIfCoverKeyExists();
      if (isCoveKeyExists === false) {
        const apiKey = `${process.env.REACT_APP_API_KEY}`;
        const url = `${process.env.REACT_APP_HOSTEDECP_API}/J45CoverXServiceType?secretCode=${apiKey}&coverKey=${coverKey}&countryCode=${getCountryCodeValue(ecpCountryCode)}&isEnabled=1`;
        const res = await axios.post(url);

        if (res?.data === true) {
          isRecordInserted = true;
        }
      }
    }
    catch {
      let message = 'Unexpected error in inserting records into J45CoverXServiceType.';
      setErrorSeverity("error");
      setOpenSnackBar(true);
      setSnackbarMessage(message);
    }
    setshowLoadingOverlay(false);
    return isRecordInserted;
  };

  const _handleInsertAuditTrail = async (auditData) => {
    setshowLoadingOverlay(true);
    try {
      const apiKey = `${process.env.REACT_APP_API_KEY}`;
      const url = `${process.env.REACT_APP_HOSTEDECP_API}/AuditTrail?secretCode=${apiKey}`;
      const res = await axios.post(url, auditData);

      if (res?.data === true) {
        return true;
      }
    }
    catch {
      let message = 'Unexpected error in inserting records AuditTrail';
      setErrorSeverity("error");
      setOpenSnackBar(true);
      setSnackbarMessage(message);
    }
    setshowLoadingOverlay(false);
  };

  const CountryCodeChange = async (countryCode) => {
    if (countryCode === '' || countryCode === null) {
      setCountryCode('');
    }
    else {
      setCountryCode(countryCode)
    }
  }

  const getCountryCodeLabel = async (countryCode) => {
    if (countryCode === '' || countryCode === null) {
      setCountryCode('');
    }
    else {
      let _countryCodeLabel = countryCodes.filter(c => c.value.trim() === countryCode)[0];
      let countryCodeLabel = _countryCodeLabel.value.trim() + " - " + _countryCodeLabel.label;
      setCountryCode(countryCodeLabel);
    }
  }

  function getCountryCodeValue(countryCode) {
    let countrycodeVal = '';
    if (countryCode === '' || countryCode === null) {
      countrycodeVal = '';
    }
    else {
      let _countryCodeValue = countryCode.split('-')[0];
      countrycodeVal = _countryCodeValue.trim();
    }
    return countrycodeVal;
  }

  const _refreshForm = () => {
    setDescription('');
    setProviderConfigId('');
    setTwillioSid('');
    setAuthCode('');
    setMessageSid('');
    setCountryCode('');
    setCommStartTime(null);
    setCommEndTime(null);
    setUtcOffsetHour(null);
    setUtcOffsetMinute(null);
    setCheckforUnsol(false);
    setIsDefault(false);
    setIsDuplicateMessageGroupId(false);
    setIsOverrideMessageGroupId(false);
  };

  const convertTimeStringToTimeFormat = (timeString) => {
    if (timeString == null) {
      return null;
    }
    else {
      let hours, minutes;
      const [timePart, period] = timeString.split(' ');
      [hours, minutes] = timePart.split(':').map(Number);

      if (period === 'PM' && hours < 12) {
        hours += 12;
      } else if (period === 'AM' && hours === 12) {
        hours = 0;
      }
      const date = new Date(0, 0, 0, hours, minutes);
      return date;
    }
  };

  function convertTo24HourFormat(timeString) {
    const [time, period] = timeString.split(' ');
    const [hour, minute] = time.split(':');
    let formattedHour = parseInt(hour);

    if (period === 'PM') {
      formattedHour += 12;
    }

    return `${formattedHour}:${minute}`;
  }

  const handleCommStartTime = async (startTime) => {
    setCommStartTime(startTime.toLocaleTimeString())
  };

  const handleCommEndTime = async (endTime) => {
    setCommEndTime(endTime.toLocaleTimeString());
  };

  const _handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  return (
    <React.Fragment>
      <div>
        <ValidatorForm onSubmit={_handleSubmitButton}>
          <Box p={2} bgcolor="background.paper">
            <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={3}>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <Autocomplete
                    noOptionsText={'Select CoverKey'}
                    id="Cover"
                    value={coverKeyLabel}
                    options={coverKeyList.map((item) => item.coverKey + " - " + item.orgName)}
                    onChange={(event, value) => CoverKeyChange(value)}
                    renderInput={(params) => <TextField required {...params} label="Cover Key" variant="outlined" />}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <Autocomplete
                    id="Location"
                    value={locKeyLabel}
                    options={lockeyList.map((item) => item.locKey + " - " + item.orgName)}
                    onChange={(event, value) => LocKeyChange(value)}
                    disabled={isFilterLocKeyDisabled}
                    renderInput={(params) => <TextField required {...params} label="Location" variant="outlined" />}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box p={4} bgcolor="background.paper" sx={{ border: '1px solid grey' }} >
            <Typography variant='h5'>
              <h3 style={{ margin: 0 }}>Hosted ECP</h3>
            </Typography>
            <div>
              <Grid container alignItems="flex-start" spacing={6} >
                <Grid item lg={8} md={8}>
                  <TextValidator
                    required
                    validators={['required']}
                    errorMessages={['this field is required']}
                    fullWidth
                    value={ecpDescription}
                    onInput={(e) => setDescription(e.target.value)}
                    label="Description"
                    name="Description"
                  />
                </Grid>
              </Grid>
            </div>

            <div>
              <Grid container alignItems="flex-start" spacing={6} >
                <Grid item lg={4} md={6}>
                  <TextValidator
                    required
                    validators={['required']}
                    errorMessages={['this field is required']}
                    fullWidth
                    value={ecpTwillioSid}
                    onInput={(e) => setTwillioSid(e.target.value)}
                    label="Twillio SID"
                    name="TwillioSid"
                  />
                </Grid>
                <Grid item lg={4} md={6}>
                  <TextValidator
                    required
                    validators={['required']}
                    errorMessages={['this field is required']}
                    fullWidth
                    value={ecpAuthCode}
                    onInput={(e) => setAuthCode(e.target.value)}
                    label="Auth Code"
                    name="AuthCode"
                  />
                </Grid>
              </Grid>
            </div>
            <div>
              <Grid container alignItems="flex-start" spacing={6} >
                <Grid item lg={4} md={6}>
                  <TextValidator
                    required
                    validators={['required']}
                    errorMessages={['this field is required']}
                    fullWidth
                    value={ecpMessageSid}
                    onInput={(e) => setMessageSid(e.target.value)}
                    onBlur={(ev) => {
                      if (isOverrideMessageGroupId === false) {
                        checkIfDuplicateMessageGroupId(ecpMessageSid)
                      }
                      ev.preventDefault();
                    }}
                    label="Message SID"
                    name="MessageSid"
                  />
                </Grid>
                <Grid item lg={4} md={6}>
                  <Autocomplete
                    noOptionsText={'Choose the Country Code'}
                    options={countryCodes.map((item) => item.value + " - " + item.label)}
                    value={ecpCountryCode}
                    onChange={(event, value) => CountryCodeChange(value)}
                    label="Country Code"
                    name="CountryCode"
                    renderInput={(params) => <TextField required  {...params} name="countryCode" label="Country Code" margin="dense" />}
                  >
                  </Autocomplete>
                </Grid>
              </Grid>
            </div>

            <div>
              <Grid container alignItems="flex-start" spacing={6} >
                <Grid item lg={4} md={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ValidatedTimePicker
                      required
                      validators={["required"]}
                      errorMessages={["Communucations start time is required"]}
                      label="Comm Start Time"
                      fullWidth
                      autoComplete="off"
                      margin="none"
                      id="comm-start-time"
                      value={convertTimeStringToTimeFormat(ecpCommStartTime)}
                      onChange={(e) => handleCommStartTime(e)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <AccessTimeIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item lg={4} md={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ValidatedTimePicker
                      required
                      validators={["required"]}
                      errorMessages={["Communications end time is required"]}
                      label="Comm End Time"
                      fullWidth
                      autoComplete="off"
                      margin="none"
                      id="comm-end-time"
                      value={convertTimeStringToTimeFormat(ecpCommEndTime)}
                      onChange={(e) => handleCommEndTime(e)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <AccessTimeIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>

            </div>
            <div>
              <Grid container alignItems="flex-start" spacing={4} >
                <Grid item xs={2}>
                  <SelectValidator
                    fullWidth
                    required
                    validators={['required']}
                    errorMessages={['this field is required']}
                    value={ecpUtcOffsetHour}
                    onChange={(e) => setUtcOffsetHour(e.target.value)}
                    label="Utc Offset Hour"
                    name="UtcOffsetHour"
                    InputLabelProps={{ shrink: true }}
                  >
                    <MenuItem value='' disabled></MenuItem>
                    <MenuItem value={-12}>-12</MenuItem>
                    <MenuItem value={-11}>-11</MenuItem>
                    <MenuItem value={-10}>-10</MenuItem>
                    <MenuItem value={-9}>-9</MenuItem>
                    <MenuItem value={-8}>-8</MenuItem>
                    <MenuItem value={-7}>-7</MenuItem>
                    <MenuItem value={-6}>-6</MenuItem>
                    <MenuItem value={-5}>-5</MenuItem>
                    <MenuItem value={-4}>-4</MenuItem>
                    <MenuItem value={-3}>-3</MenuItem>
                    <MenuItem value={-2}>-2</MenuItem>
                    <MenuItem value={-1}>-1</MenuItem>
                    <MenuItem value={+0}>+0</MenuItem>
                    <MenuItem value={+1}>+1</MenuItem>
                    <MenuItem value={+2}>+2</MenuItem>
                    <MenuItem value={+3}>+3</MenuItem>
                    <MenuItem value={+4}>+4</MenuItem>
                    <MenuItem value={+5}>+5</MenuItem>
                    <MenuItem value={+6}>+6</MenuItem>
                    <MenuItem value={+7}>+7</MenuItem>
                    <MenuItem value={+8}>+8</MenuItem>
                    <MenuItem value={+9}>+9</MenuItem>
                    <MenuItem value={+10}>+10</MenuItem>
                    <MenuItem value={+11}>+11</MenuItem>
                    <MenuItem value={+12}>+12</MenuItem>
                    <MenuItem value={+13}>+13</MenuItem>
                    <MenuItem value={+14}>+14</MenuItem>
                  </SelectValidator>
                </Grid>
                <Grid item xs={2}>
                  <SelectValidator
                    fullWidth
                    required
                    validators={['required']}
                    errorMessages={['this field is required']}
                    value={ecpUtcOffsetMinute}
                    onChange={(e) => setUtcOffsetMinute(e.target.value)}
                    label="Utc Offset Minute"
                    name="UtcOffsetMinute"
                    InputLabelProps={{ shrink: true }}
                  >
                    <MenuItem value='' disabled></MenuItem>
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                    <MenuItem value={45}>45</MenuItem>
                  </SelectValidator>
                </Grid>
                <Tooltip title="Patients can initiate conversation if this field is checked">
                  <Grid container item xs={2}>
                    <MaterialCheckBox
                      onChange={(e) => setCheckforUnsol(e.target.checked)}
                      value={ecpCheckforUnsol}
                      name="CheckforUnsol"
                      label="Check for Unsol"
                    />
                  </Grid>
                </Tooltip>
                <Grid container item xs={2} alignItems='flex-end'>
                  <MaterialCheckBox
                    onChange={(e) => setIsDefault(e.target.checked)}
                    value={ecpIsDefault}
                    disabled={true}
                    label="Is Default"
                    name="IsDefault"
                  />
                </Grid>
                <Grid container alignItems="flex-start" spacing={2} >
                  {isDuplicateMessageGroupId &&
                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        control={
                          <Checkbox style={{ padding: 4 }} defaultChecked color="default" checked={isOverrideMessageGroupId} onChange={(e) =>
                            _handleOverrideMessageGroupId(e.target.checked)} />
                        }
                        label={
                          <span style={{ fontSize: '15px' }}> Select the checkbox to override duplicate Message Group Id</span>
                        }
                      />
                    </Grid>
                  }
                </Grid>
                <Grid container alignItems="flex-start" spacing={2} >
                  <Grid item xs={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isAddButtonDisabled}
                    >
                      {submitButtonLabel}
                    </Button>
                  </Grid>
                  {isRecordExists &&
                    <Grid item xs={1}>
                      <Button
                        type="button"
                        onClick={(e) => { _handleRemoveHostedCheck(e); }}
                        variant="contained"
                        color="primary"
                      >
                        Remove
                      </Button>
                    </Grid>
                  }
                </Grid>
              </Grid>
              <Dialog
                maxWidth="md"
                open={openConfirmDialog}
                onClose={_handleCloseConfirmDialog}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogTitle id="responsive-dialog-title">{"This action cannot be undone."}</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Are you sure you want to delete this entry?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={_handleRemoveHosted} color="primary">
                    Yes
                  </Button>
                  <Button onClick={_handleCloseConfirmDialog} color="primary" autoFocus>
                    No
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            <Divider orientation="vertical" variant="middle" flexItem />
          </Box>
        </ValidatorForm>
      </div >

      <Overlay show={showLoadingOverlay}>
        <i className="spinner-eclipse"></i>
      </Overlay>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnackBar} onClose={() => setOpenSnackBar(false)}>
        <Alert onClose={() => setOpenSnackBar(false)} severity={errorSeverity}>
          {SnackbarMessage}
        </Alert>
      </Snackbar>
    </React.Fragment>

  )
}